import React from "react"
import {Helmet} from "react-helmet";

export default function AboutLayout({children}) {
    return (
        <>
            <div>
                {children}
            </div>
        </>
    )
}
