import React from "react";
import {Link} from "gatsby";

import glassview from "../works/Glassview/hero.png";
import welcomech from "../works/WelcomeCH/main.png";
import sg1 from "../works/SG-1/main.png";


import InterProtocolMp4 from "../works/InterProtocol/video/main.mp4";
import InterProtocol from "../works/InterProtocol/video/main.webm";
import AbleMp4 from "../works/Able/video/main.mp4";
import Able from "../works/Able/video/main.webm";
import TurfgrassMp4 from "../works/Turfgrass/video/logoAnim.mp4";
import Turfgrass from "../works/Turfgrass/video/logoAnim.webm";
import ImageSlider from "./imageSlider";

import team01 from "../images/team/team-0-1.png";
import team02 from "../images/team/team-0-2.png";
import team03 from "../images/team/team-0-3.png";
import team04 from "../images/team/team-0-4.png";
import team05 from "../images/team/team-0-5.png";
import team11 from "../images/team/team-1-1.png";
import team12 from "../images/team/team-1-2.png";
import team13 from "../images/team/team-1-3.png";
import team14 from "../images/team/team-1-4.png";
import team15 from "../images/team/team-1-5.png";
import team21 from "../images/team/team-2-1.png";
import team22 from "../images/team/team-2-2.png";
import team23 from "../images/team/team-2-3.png";
import team24 from "../images/team/team-2-4.png";
import team25 from "../images/team/team-2-5.png";
import team31 from "../images/team/team-3-1.png";
import team32 from "../images/team/team-3-2.png";
import team33 from "../images/team/team-3-3.png";
import team34 from "../images/team/team-3-4.png";
import team35 from "../images/team/team-3-5.png";
import team36 from "../images/team/team-3-6.png";
import team41 from "../images/team/team-4-1.png";
import team42 from "../images/team/team-4-2.png";
import team43 from "../images/team/team-4-3.png";
import team44 from "../images/team/team-4-4.png";
import team45 from "../images/team/team-4-5.png";
import team51 from "../images/team/team-5-1.png";
import team52 from "../images/team/team-5-2.png";
import team53 from "../images/team/team-5-3.png";
import team54 from "../images/team/team-5-4.png";
import team55 from "../images/team/team-5-5.png";
import team56 from "../images/team/team-5-6.png";



class Team extends React.Component {
    render() {
        return (
            <div className={'dn-grid'}>
                <div className={'grid-p50 item item-black'}>
                    <div className={'image'}>
                        <ImageSlider time={9} images={[
                            team01, team02, team03, team04, team05
                        ]}/>
                    </div>
                </div>
                <div className={'grid-p50 item item-right item-black'}>
                    <div className={'image ratio'} style={{aspectRatio: "128/83"}}>
                        <video id={'team-video-5'} autoPlay={true} preload={'none'} loop={true} playsInline={true} muted={true} style={{ objectFit: 'cover', width: '100%', height: '100%' }}>
                            <source src={'https://player.vimeo.com/progressive_redirect/playback/1006384418/rendition/720p/file.mp4?loc=external&signature=3dbd01745eeb81f5a6ad45b3ca7d9c0c4c4362027eaf86ce52369abfe96abe67'}/>
                        </video>
                    </div>
                </div>
                <div className={'grid-c33 item item-right'}>
                    <div className={'image'}>
                        <ImageSlider time={16} images={[
                            team21, team22, team23, team24, team25
                        ]}/>
                    </div>
                </div>
                <div className={'grid-c33 item item-black'}>
                    <div className={'image'}>
                        <ImageSlider time={11} images={[
                            team11, team12, team13, team14, team15
                        ]}/>
                    </div>
                </div>

                <div className={'grid-c33 item item-right item-black'}>
                    <div className={'image ratio ratio-1x1'}>
                        <video className={''} id={'team-video-2'} autoPlay={true} preload={'none'} loop={true} playsInline={true} muted={true} style={{ objectFit: 'cover', width: '100%', height: '100%' }}>
                            <source src={'https://player.vimeo.com/progressive_redirect/playback/1006385027/rendition/720p/file.mp4?loc=external&signature=e6fd4813e9e4b3a29e895a2d35f237d9a5996584c0f9321778303c0a61c74706'}/>
                        </video>
                    </div>
                </div>

                <div className={'grid-c33 item item-black'}>
                    <div className={'image'}>
                        <ImageSlider time={13} timing={'section-image-slide-12'} images={[
                            team31, team32, team33, team34, team35, team36
                        ]}/>
                    </div>
                </div>

                <div className={'grid-c66 item item-right'}>
                    <div className={'image'}>
                        <video id={'team-video-3'} autoPlay={true} preload={'none'} loop={true} playsInline={true} muted={true} style={{ objectFit: 'cover', width: '100%', height: '100%' }}>
                            <source src={'https://player.vimeo.com/progressive_redirect/playback/1006385624/rendition/720p/file.mp4?loc=external&signature=a0284b463b06f86df8641e4b88f9387ece2e779094264e0f94b151f31c488285'}/>
                        </video>
                    </div>
                </div>

                <div className={'grid-c33 item item-black'}>
                    <div className={'image'}>
                        <ImageSlider time={17} images={[
                            team41, team42, team43, team44, team45
                        ]}/>
                    </div>
                </div>


                <div className={'grid-c33 item item-left'}>
                    <div className={'image ratio ratio-1x1'}>
                        <video id={'team-video-4'} autoPlay={true} preload={'none'} loop={true} playsInline={true} muted={true} style={{ objectFit: 'cover', width: '100%', height: '100%' }}>
                            <source src={'https://player.vimeo.com/progressive_redirect/playback/1006386678/rendition/540p/file.mp4?loc=external&signature=41f2a17d705e409da3e8a8ed3259d8a962b0a84395a7cfaaed010c47de85acd6'}/>
                        </video>
                    </div>
                </div>

                <div className={'grid-c33 item item-black'}>
                    <div className={'image'}>
                        <video id={'team-video-6'} autoPlay={true} preload={'none'} loop={true} playsInline={true} muted={true} style={{ objectFit: 'cover', width: '100%', height: '100%' }}>
                            <source src={'https://player.vimeo.com/progressive_redirect/playback/1006386157/rendition/540p/file.mp4?loc=external&signature=7cfb643f5bc3261a713a7d8faac8c534a74d1b431c11ffa5eb4a35fbddac4fc5'}/>
                        </video>
                    </div>
                </div>

                <div className={'grid-c33 item item-right item-black'}>
                    <div className={'image'}>
                        <ImageSlider time={19} timing={'section-image-slide-12'} images={[
                            team51, team52, team53, team54, team55, team56
                        ]}/>
                    </div>
                </div>


                <div className={'grid-c66 item'}>
                    <div className={'image'}>
                        <video id={'team-video-1'} autoPlay={true} preload={'none'} loop={true} playsInline={true} muted={true} style={{ objectFit: 'cover', width: '100%', height: '100%' }}>
                            <source src={'https://player.vimeo.com/progressive_redirect/playback/1005952082/rendition/720p/file.mp4?loc=external&signature=f11356abd22859fadd86609fd873e5af543c9174a6f64987d80a19c9af415e60'}/>
                        </video>
                    </div>
                </div>
            </div>
        );
    }
}

export default Team;
