import * as React from "react"
import Header from "../components/header";
import Footer from "../components/footer";
import piechart from "../images/pie-chart.png";
import map from "../images/map.png";
import {Helmet} from "react-helmet";
import Team from "../components/team";
import AboutLayout from "../components/AboutLayout";

const AboutUs = () => {
    const title = 'Meet the Team Behind Designatives | Creative Agency in Budapest, Hungary';
    const description = 'Discover the team at Designatives, a creative agency with global reach and local talent. Based in Budapest, Hungary, we deliver exceptional branding, web design, apps, and more. Get to know who we are and how we work.';
    return (
        <main>
            <Helmet>
                <title>{title}</title>

                <meta name="description" content={description}/>
                <meta name="DC.description" content={description}/>
                <meta name="DC.title" content={title}/>

                <meta property="og:description" content={description}/>
                <meta property="og:site_name" content={title}/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:site" content="@designatives"/>
                <meta name="twitter:title" content="Designatives"/>
                <meta name="twitter:description" content={description}/>
                <meta name="twitter:creator" content="@designatives"/>
                <meta property="og:image" content="https://designatives.com/about-social-image.png"/>
                <meta name="twitter:image" content="https://designatives.com/about-social-image.png"/>
            </Helmet>
            <Header/>
            <AboutLayout>
                <div className={'about-us-page'}>
                    <section className="hero-section">
                        <div className="container h-100">
                        <div className="row h-100 align-items-center">
                                <div className={'col-12 col-md-6 order-2 order-md-1'}>
                                    <h1 className={'level3'}>Global Reach, Local Craft.</h1>
                                    <p>We're proud to partner with startups and enterprises around the globe. Every project is thoughtfully crafted by our team right here in Budapest, Hungary.</p>
                                </div>

                                <div className={'col-12 col-md-6 order-1 order-md-2'}>
                                    <img className={'d-xl-none map-image'} src={map} alt="Designatives"/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="piechart-section mt-5 mt-md-0">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className={'col-12 col-md-6'}>
                                    <img src={piechart} alt={'Designatives'}/>
                                </div>
                                <div className={'col-12 col-md-6'}>
                                    <h2 className={'level3'}>This is our craft. <br/>
                                        Every slice is delicious.</h2>
                                    <p>From logo design to complete branding, from simple websites to complex web applications—we do it all. We love our work and are always exploring new ways to expand our expertise.</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="team-section">
                        <div className="container">
                            <div className="row">
                                <div className={'col-12'}>
                                    <h2>This is our team.<br/>
                                        This is how we have fun.</h2>
                                    <Team/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </AboutLayout>
            <Footer/>
        </main>
    )
}

export default AboutUs
